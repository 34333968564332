@font-face {
    font-family: 'StretchPro';
    src: url('StretchPro.otf') format('opentype');
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Neou-Thin';
    src: url('Neou-Thin.otf') format('opentype');
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Neou-Bold';
    src: url('Neou-Bold.otf') format('opentype');
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cocogoose';
    src: url("Cocogoose\ Pro-trial.ttf") format('truetype');
    font-style: normal;
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cocogoose';
    src: url("Cocogoose\ Pro\ Italic-trial.ttf") format('truetype');
    font-style: italic;
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cocogoose';
    src: url("Cocogoose\ Pro\ Light-trial.ttf") format('truetype');
    font-style: normal;
    font-weight: 200;
    font-display: swap;
}

@font-face {
    font-family: 'Cocogoose';
    src: url("Cocogoose\ Pro\ Light Italic-trial.ttf") format('truetype');
    font-style: italic;
    font-weight: 200;
    font-display: swap;
}

@font-face {
    font-family: 'Cocogoose';
    src: url("Cocogoose\ Pro\ Semilight-trial.ttf") format('truetype');
    font-style: normal;
    font-weight: 300;
    font-display: swap;
}

@font-face {
    font-family: 'Cocogoose';
    src: url("Cocogoose\ Pro\ Semilight\ Italic-trial.ttf") format('truetype');
    font-style: italic;
    font-weight: 300;
    font-display: swap;
}

@font-face {
    font-family: 'Cocogoose';
    src: url("Cocogoose\ Pro\ Thin-trial.ttf") format('truetype');
    font-style: normal;
    font-weight: 100;
    font-display: swap;
}

@font-face {
    font-family: 'Cocogoose';
    src: url("Cocogoose\ Pro\ Thin Italic-trial.ttf") format('truetype');
    font-style: italic;
    font-weight: 100;
    font-display: swap;
}

@font-face {
    font-family: 'Century Gothic';
    src: url("GOTHIC.TTF") format('truetype');
    font-style: normal;
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: 'Century Gothic';
    src: url("GOTHICI.TTF") format('truetype');
    font-style: italic;
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: 'Century Gothic';
    src: url("GOTHICB.TTF") format('truetype');
    font-style: normal;
    font-weight: 700;
    font-display: swap;
}

@font-face {
    font-family: 'Century Gothic';
    src: url("GOTHICBI.TTF") format('truetype');
    font-style: italic;
    font-weight: 700;
    font-display: swap;
}
