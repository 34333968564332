@tailwind base;
@tailwind components;
@tailwind utilities;

.page-header {
  @apply text-center font-stretch text-3xl md:text-5xl lg:text-6xl;
}

.page-sub-header {
  @apply text-center font-stretch text-base text-greyblack md:text-2xl lg:text-3xl;
}

.container * {
  z-index: 10;
}

.hamburger-react {
  z-index: 50;
  position: fixed !important;
  top: 0.5em;
  right: 1em;

  overflow: hidden;

  transition: transform 0.2s ease-in-out;
}

.hamburger-react:hover {
  transform: scale(1.1);
}

.holo-panel {
  background-image: url("/img/pattern_dark_faded_edge_L.webp"),
    url("/img/pattern_dark_faded_edge_R.webp"),
    url("/img/holographic_background.webp");
  background-size: 25vw 25vw, 25vw 25vw, cover;
  background-repeat: repeat-y, repeat-y, no-repeat;
  background-position-x: left, right, left;
}

.gallery-row span {
  -webkit-box-shadow: 0px 5px 15px -3px rgba(0, 0, 0, 1);
  box-shadow: 0px 5px 15px -3px rgba(0, 0, 0, 1);
}

.no-ligature {
  font-variant-ligatures: none;
}

.angry-shake {
  transform-origin: bottom left;
}

.shake-activate {
  transform: translateY(-0.5em);
  transition: transform 0.1s ease-out;
}

.shake-anim {
  animation-name: shake;
  animation-duration: 0.3s;
  animation-timing-function: ease-in;
}

@keyframes shake {
  0% {
    -webkit-transform: translate(30px);
  }
  20% {
    -webkit-transform: translate(-30px);
  }
  40% {
    -webkit-transform: translate(15px);
  }
  60% {
    -webkit-transform: translate(-15px);
  }
  80% {
    -webkit-transform: translate(8px);
  }
  100% {
    -webkit-transform: translate(0px);
  }
}

@keyframes check-stroke {
  25% {
    stroke-dashoffset: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: check-stroke 3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@layer components {
  .label {
    @apply mb-2 block font-gothic text-base text-pastelpink;
  }

  .text-input {
    @apply block w-full border-b-2 border-pastelpink bg-transparent font-gothic text-base text-pastelpink outline-none;
  }

  .text-input:focus {
    background: transparent;
  }
}

.carousel-item {
  opacity: 0;
  transition: 0.2s ease-in-out opacity;

  position: absolute;
  user-select: none;
  display: none;
}

.carousel-item.is-active p {
  opacity: 1;
}

.carousel-item img {
  -webkit-box-shadow: 0px 12px 15px -3px rgba(0, 0, 0, 1);
  box-shadow: 0px 12px 15px -3px rgba(0, 0, 0, 1);

  image-rendering: optimizeSpeed;
  /*transition: 0.2s ease-in-out transform;*/
}

.carousel-item.is-active,
.carousel-item.is-prev,
.carousel-item.is-next {
  transition: 0.2s ease-in-out transform;
}

.carousel-item.is-active {
  opacity: 1;
  display: inline;

  left: 50%;
  z-index: 10;

  perspective: 50rem;
  transform: translateX(-50%);
}

.carousel-item.is-active img {
  transform: scale(125%);
}

.carousel-item.is-prev {
  opacity: 0.6;
  display: inline;
  left: 50%;
  z-index: 1;

  transform: translateX(-125%);
}

.carousel-item.is-next {
  opacity: 0.6;
  display: inline;
  left: 50%;
  z-index: 1;

  transform: translateX(25%);
}

@media (max-width: 768px) {
  .carousel-item.is-prev {
    transform: translateX(-95%);
  }
  .carousel-item.is-next {
    transform: translateX(-5%);
  }
}

div.splide__arrows {
  position: absolute;
  width: 100%;

  z-index: 20;
}

.splide__arrow--prev {
  position: absolute;
  left: 0;
}

.splide__arrow--next {
  position: absolute;
  right: 0;
}

.text-holo {
  background-image: url("/img/holographic_background.webp");

  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gallery-show-img {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  height: 100%;
  opacity: 0;
}

@keyframes img-show-1 {
  0% {
    opacity: 0%;
  }
  20% {
    opacity: 100%;
  }
  40% {
    opacity: 0%;
  }
  60% {
    opacity: 0%;
  }
  80% {
    opacity: 0%;
  }
}

.index-grid {
  display: grid;
  grid-template-columns: 1fr 2fr;
  column-gap: 20px;
  row-gap: 20px;
}

@keyframes img-show-2 {
  0% {
    opacity: 0%;
  }
  1.5% {
    opacity: 0%;
  }
  3.5% {
    opacity: 100%;
  }
  16.67% {
    opacity: 100%;
  }
  18.67% {
    opacity: 0%;
  }
}

span:nth-child(1) > .menu-show-img {
  animation-name: img-show-2;
  animation-iteration-count: infinite;
  animation-duration: 30s;
  animation-timing-function: ease-in-out;
}

span:nth-child(2) > .menu-show-img {
  animation-name: img-show-2;
  animation-iteration-count: infinite;
  animation-duration: 30s;
  animation-delay: 5s;
  animation-timing-function: ease-in-out;
}

span:nth-child(3) > .menu-show-img {
  animation-name: img-show-2;
  animation-iteration-count: infinite;
  animation-duration: 30s;
  animation-delay: 10s;
  animation-timing-function: ease-in-out;
}

span:nth-child(4) > .menu-show-img {
  animation-name: img-show-2;
  animation-iteration-count: infinite;
  animation-duration: 30s;
  animation-delay: 15s;
  animation-timing-function: ease-in-out;
}

span:nth-child(5) > .menu-show-img {
  animation-name: img-show-2;
  animation-iteration-count: infinite;
  animation-duration: 30s;
  animation-delay: 20s;
  animation-timing-function: ease-in-out;
}

span:nth-child(6) > .menu-show-img {
  animation-name: img-show-2;
  animation-iteration-count: infinite;
  animation-duration: 30s;
  animation-delay: 25s;
  animation-timing-function: ease-in-out;
}

.menu-show-img {
  opacity: 0;
}

span:nth-child(1) > .gallery-show-img {
  animation-name: img-show-1;
  animation-iteration-count: infinite;
  animation-duration: 20s;
  animation-delay: -20s;
  animation-timing-function: ease-in-out;
}

span:nth-child(2) > .gallery-show-img {
  animation-name: img-show-1;
  animation-iteration-count: infinite;
  animation-duration: 20s;
  animation-delay: -4s;
  animation-timing-function: ease-in-out;
}

span:nth-child(3) > .gallery-show-img {
  animation-name: img-show-1;
  animation-iteration-count: infinite;
  animation-duration: 20s;
  animation-delay: -8s;
  animation-timing-function: ease-in-out;
}

span:nth-child(4) > .gallery-show-img {
  animation-name: img-show-1;
  animation-iteration-count: infinite;
  animation-duration: 20s;
  animation-delay: -12s;
  animation-timing-function: ease-in-out;
}

span:nth-child(5) > .gallery-show-img {
  animation-name: img-show-1;
  animation-iteration-count: infinite;
  animation-duration: 20s;
  animation-delay: -16s;
  animation-timing-function: ease-in-out;
}

@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes slideTopToBottom {
    from {
        transform: translateY(-100vh);
    }

    to {
        transform: translateY(0%);
    }
}

@keyframes slideBottomToTop {
    from {
        transform: translateY(100vh);
    }

    to {
        transform: translateY(0%);
    }
}

@keyframes slideLeftToRight {
    from {
        transform: translateX(-100vw);
    }

    to {
        transform: translateX(0%);
    }
}

@keyframes slideRightToLeft {
    from {
        transform: translateX(100vw);
    }

    to {
        transform: translateX(0%);
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}

.animate-fadein {
    animation-name: fade-in;
    animation-iteration-count: 1;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
}

.animate-slideTopToBottom {
    animation-name: slideTopToBottom;
    animation-iteration-count: 1;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
}

.animate-slideBottomToTop {
    animation-name: slideBottomToTop;
    animation-iteration-count: 1;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
}

.animate-slideLeftToRight {
    animation-name: slideLeftToRight;
    animation-iteration-count: 1;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
}

.animate-slideRightToLeft {
    animation-name: slideRightToLeft;
    animation-iteration-count: 1;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
}

.animate-slideLeftToRightFast {
    animation-name: slideLeftToRight;
    animation-iteration-count: 1;
    animation-duration: 1s;
    animation-timing-function: cubic-bezier(.05,.74,.26,.96);
}

.animate-slideRightToLeftFast {
    animation-name: slideRightToLeft;
    animation-iteration-count: 1;
    animation-duration: 1s;
    animation-timing-function: cubic-bezier(.05,.74,.26,.96);
}

.animate-pulse {
    animation-name: pulse;
    animation-iteration-count: 1;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
}

:root {
  color-scheme: dark;
}

@media screen and (min-width: 768px) {
  .index-grid {
    grid-template-columns: 1fr 4fr;
    column-gap: 32px;
    row-gap: 32px;
  }
}

@media screen and (min-width: 1280px) {
  .index-grid {
    grid-template-columns: 1fr 3fr 360px;
    column-gap: 32px;
    row-gap: 32px;
  }
}

@media screen and (min-width: 1536px) {
  .index-grid {
    grid-template-columns: 1fr 3fr 440px;
    column-gap: 32px;
    row-gap: 32px;
  }
}
