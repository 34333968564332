.blog-previews .blog-container {
  background: none;
  width: fit-content;
  max-width: 768px;
  min-height: auto;
  @apply shadow-blog-panel;
}

.blog-previews .blog-container .blog-end {
  display: none;
}

.blog-previews .blog-container > div:last-child {
  background: none;
  display: none;
}

.blog-previews .blog-container > p {
  display: none;
}

.blog-previews .blog-container > div:first-of-type {
  display: none;
}

.blog-previews .blog-container > p:first-of-type {
  display: none;
}

.blog-previews .blog-post {
  max-height: 320px;
  overflow-y: hidden;
  box-shadow: none;
}

.blog-previews .blog-post > div:nth-child(3) {
  margin-top: 4rem;
}

.blog-previews .blog-container > div:nth-child(2) {
  box-shadow: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.blog-previews .blog-container > div > a {
  display: none;
}
.blog-previews .blog-content {
  -webkit-mask-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 0px,
    rgba(0, 0, 0, 0) 320px
  );
  mask-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 0px,
    rgba(0, 0, 0, 0) 320px
  );
}

.timeline {
  -webkit-mask-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 10px,
    rgba(0, 0, 0, 1) 16rem,
    rgba(0, 0, 0, 1) calc(100% - 16rem),
    rgba(0, 0, 0, 0) calc(100% - 10px)
  );
  mask-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 10px,
    rgba(0, 0, 0, 1) 16rem,
    rgba(0, 0, 0, 1) calc(100% - 16rem),
    rgba(0, 0, 0, 0) calc(100% - 10px)
  );

  -ms-overflow-style: none;
  scrollbar-width: none;
}

.timeline::-webkit-scrollbar {
  display: none;
}

.timeline-mobile {
  -webkit-mask-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 32px,
    rgba(0, 0, 0, 1) 72px,
    rgba(0, 0, 0, 1) calc(100% - 72px),
    rgba(0, 0, 0, 0) calc(100% - 32px)
  );
  mask-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 32px,
    rgba(0, 0, 0, 1) 72px,
    rgba(0, 0, 0, 1) calc(100% - 72px),
    rgba(0, 0, 0, 0) calc(100% - 32px)
  );

  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-behavior: smooth;
}

.timeline-mobile::-webkit-scrollbar {
  display: none;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.blog-previews-mobile .blog-post {
  max-height: 400px;
  overflow-y: hidden;
}

.blog-previews-mobile .blog-post > div:first-of-type {
  box-shadow: none;
  margin-bottom: 0;
}

.blog-previews-mobile .blog-content {
  box-shadow: none;
  padding-top: 0;
  max-height: 120px;
}

.blog-previews-mobile .blog-container .blog-end {
  display: none;
}

.blog-previews-mobile .blog-container > div > a {
  display: none;
}

.blog-previews-mobile .blog-container {
  background: none;
  min-height: auto;
  @apply shadow-blog-panel;
}

.blog-previews-mobile .blog-link {
  padding-top: 0;
  @apply bg-greyblack;
}

.blog-previews-mobile .blog-container > div:first-of-type {
  display: none;
}

.blog-previews-mobile .blog-container > p:first-of-type {
  display: none;
}

.blog-previews-mobile .blog-container > div:nth-of-type(2) {
  box-shadow: none;
}

.blog-previews-mobile .blog-container > div:last-child {
  background: none;
  display: none;
}

.blog-previews-mobile .blog-content {
  -webkit-mask-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 0px,
    rgba(0, 0, 0, 0) 115px
  );
  mask-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 0px,
    rgba(0, 0, 0, 0) 115px
  );
}

.blog-container .blog-end > div:first-child {
  grid-row: 2 / 3;
}

@media (min-width: 640px) {
  .blog-container .blog-end > div:first-child {
    grid-row: 1 / 2;
  }
}
