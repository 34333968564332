.blog-post h1 {
}

.blog-post h2 {
  @apply my-5 font-gothic text-2xl font-bold text-periwinkle;
}

.blog-post h3 {
  @apply my-5 font-gothic text-xl font-bold text-periwinkle;
}

.blog-post h4 {
  @apply my-5 font-gothic text-lg font-bold text-periwinkle;
}

.blog-post h5 {
  @apply my-5 font-gothic text-base font-bold italic text-periwinkle;
}

.blog-post h6 {
  @apply my-5 font-gothic text-base font-bold text-periwinkle;
}

.blog-post p {
  @apply mb-4 font-gothic text-base text-sky;
}

.blog-post a {
  @apply font-bold text-lilac underline;
}

.blog-post ul li {
  @apply mb-2 py-2 font-gothic text-base text-sky;
}

.blog-post ol li {
  @apply my-2 font-gothic text-base text-sky;
}

.blog-post ol {
  @apply my-4 list-decimal pl-4;
}

.blog-post span {
  @apply mb-2 py-2 font-gothic text-base text-sky;
}

.date-header {
  @apply border-b-4 border-sky font-gothic text-base text-sky;
}

.blog-post .split-stack {
  /* There is a bug on chrome for android where the text in flexbox is rendered
   * slightly smaller than it should be. */
  display: block;
}

.blog-post .split-stack aside {
  flex-basis: 0;
}

.blog-post .split-stack img {
  width: 100%;
  height: auto;
}

.blog-post .quote p {
  margin-bottom: 0;
}

@media screen and (min-width: 768px) {
  .blog-post .split-stack {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }
}
